import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { mutateApp } from '@/helpers'

export default function Setup() {
  const store = useStore();

  const appTheme = computed({
    get: () => {
      return store.state.app.themeChosen
    },
    set: (value) => {
      return toogleTheme(value);
    }
  });

  const darkLightChange = () => {
    if (document.getElementById("app").classList.contains("dark-theme")) toogleTheme("light")
    else if (document.getElementById("app").classList.contains("light-theme")) toogleTheme("dark")
    else toogleTheme()
  };

  const readThemePreference = () => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) return 'dark';
    else return 'light';
  };

  const switchTheme = (theme = 'light') => {
    let app = document.getElementById("app");
    app.className = "";
    app.classList.add(theme + "-theme");
  };

  const toogleTheme = (theme = 'system') => {
    mutateApp('themeChosen', theme);
    if (theme === 'system') {
      theme = readThemePreference();
    }
    mutateApp('theme', theme);
    switchTheme(theme);
  };

  onMounted(() => {
    toogleTheme()
  });

  return {
    appTheme,
    darkLightChange,
    toogleTheme
  };
}
