<template>
  <div>
    <div class="h-fit w-100">
      <div class="mt-4 fs-3 fw-bold underline text-center">
        {{ $tcust('SignIn.title') }}
      </div>
      <CardContainer class="gradient m-auto mt-4 h-fit">
        <form
          id="signInForm"
          class="p-0"
          onsubmit="return false"
        >
          <div
            class="inside-block"
            :class="MailFailed ? 'bg-danger' : ''"
          >
            <div class="bg-box">
              <div class="p-3">
                <div class="mt-n2 mb-3 ms-2 fst-italic">
                  {{ $tcust('SignIn.email') }}
                </div>
                <!-- Email -->
                <MDBInput
                  id="Mail"
                  v-model="Mail"
                  type="email"
                  autocomplete="username"
                  required
                  :label="$tcust('common.form.email')"
                  class="text-center"
                />
                <!-- Mail failed -->
                <div v-if="MailFailed">
                  <hr class="w-75 mx-auto mt-4">
                  <div class="ps-2">
                    {{ $tcust('SignIn.failed') }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Language -->
          <div class="inside-block">
            <div class="bg-box">
              <div class="p-3">
                <div class="mt-n2 mb-3 ms-2 fst-italic">
                  {{ $tcust('SignIn.language') }}
                </div>

                <div
                  v-for="(language, index) of supportedLang"
                  :key="index"
                >
                  <div class="m-2 ps-2">
                    <MDBRadio
                      v-model="lang"
                      :label="$tcust('LangSwitcher.dropdown.'+language)"
                      :name="'signInRadioLang' + language"
                      :value="language"
                    />
                  </div>
                  <hr
                    v-if="index !== supportedLang.length - 1"
                    class="p-0 my-0 mx-3"
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Theme -->
          <div class="inside-block">
            <div class="bg-box">
              <div class="p-3">
                <div class="mt-n2 mb-3 ms-2 fst-italic">
                  {{ $tcust('SignIn.theme') }}
                </div>

                <div class="m-2 ps-2">
                  <MDBRadio
                    v-model="appTheme"
                    :label="$tcust('ThemeSwitcher.dropdown.system')"
                    name="signInRadioThemeSystem"
                    value="system"
                  />
                </div>
                <hr class="p-0 my-0 mx-3">
                <div class="m-2 ps-2">
                  <MDBRadio
                    v-model="appTheme"
                    :label="$tcust('ThemeSwitcher.dropdown.light')"
                    name="signInRadioThemeLight"
                    value="light"
                  />
                </div>
                <hr class="p-0 my-0 mx-3">
                <div class="m-2 ps-2">
                  <MDBRadio
                    v-model="appTheme"
                    :label="$tcust('ThemeSwitcher.dropdown.dark')"
                    name="signInRadioThemeDark"
                    value="dark"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Submit button -->
          <div class="inside-block">
            <div class="bg-box">
              <div class="d-flex justify-content-center p-4">
                <MDBBtn
                  color="primary"
                  block
                  class="w-75"
                  type="submit"
                  @click="submit"
                >
                  <span>{{ $tcust("SignIn.submit") }}</span>
                </MDBBtn>
              </div>
            </div>
          </div>
        </form>
      </CardContainer>
    </div>
  </div>
</template>

<script setup>
import {
  MDBInput,
  MDBBtn,
  MDBRadio
} from "mdb-vue-ui-kit";

import Setup from "./SignInSetup";
const { Mail, MailFailed, submit } = Setup();

import LangSetup from "@/components/ui/LangSwitcherSetup";
const { lang, supportedLang } = LangSetup();

import ThemeSetup from "@/components/ui/ThemeSwitcherSetup";
const { appTheme } = ThemeSetup();
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}
.gradient {
  max-width: 350px;
}
</style>
