import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Trans } from '@/plugins/translation'

export default function Setup() {
  const router = useRouter();
  const store = useStore();

  const Mail = ref(null);
  const MailFailed = ref(false);
  const Theme = ref(store.state.app.themeChosen === 'system' ? 0 : store.state.app.themeChosen === 'light' ? 1 : 2);
  const Language = ref(Trans.currentLanguage);

  const submitted = ref(false);

  const submit = async () => {
    if (!document.querySelector('form').reportValidity()) return
    submitted.value = true;
    await store
      .dispatch("register", {
        "Language": Language.value,
        "Theme": Theme.value,
        "MailAddress": Mail.value,
        "ClientSiteUrl": "https://client.eecheck.app/#/lang",
        
        // "Password":"cool",
        // "PasswordConfirmation":"cool",
        // "IsPasswordEncrypted":false,
        // "UserRole":0,
        // "PersonType":0,
        // "Civility":0,
        // "LastName":"TEST",
        // "FirstName":"FirstName",
        // "MoralName":null,
        // "IdentificationNumberType":null,
        // "IdentificationNumber":null,
        // "PhoneNumber":null,
        // "CellPhoneNumber":"06 01 86 54 13",
        // "FaxNumber":null,
        // "Address1":"66 avenue de Bellande",
        // "Address2":null,
        // "Address3":null,
        // "Address4":null,
        // "ZipCode":"07200",
        // "City":"Aubenas",
        // "Country":"FR",
        // "Base64Image":null
      })
      .then(() => {
        router.push({
          name: "SignInComplete",
        });
      })
  };

  return {
    Mail,
    MailFailed,
    submit,
    submitted
  }
}