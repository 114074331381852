import { computed, ref } from 'vue'
import { useRouter } from "vue-router";
import { Trans } from '@/plugins/translation'

export default function Setup() {
  const router = useRouter();

  const supportedLang = ref(Trans.supportedLanguages);

  const lang = computed({
    get: () => {
      return Trans.currentLanguage
    },
    set: (value) => {
      Trans.changeLanguage(value).then(() => router.push(Trans.i18nRoute(router.currentRoute._value.path)))
    }
  });

  return {
    lang,
    supportedLang
  };
}
